@import "./partials/normalize";
@import "./partials/variables";
@import "./partials/iconfont";
// @import "./partials/sprite";

html, body{
	height: 100%;
	margin:0;
	padding:0;
}

body{
	font-family:Montserrat, sans-serif;
	font-size:18px;
	color: #313131;
	line-height:1.6;
	font-weight: 300;
}

img{
	max-width: 100%;
}

a{
	display: block;
	text-decoration: none;
	transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
}

a:hover {
	text-decoration: none;
	opacity:.6;
}

.link--colored{
    // color: #fba803;
    display: inline-block;
    color: #111;
    font-weight:400;
    border-bottom: 1px dotted #413d39;
}

ul, li{
	padding:0; 
	margin:0;
	display: block;
}

label{
	line-height: 1.2;
}


// h1, .h1-like, h2, .h2-like, h3, .h3-like{
h1, .h1-like, h2, .h2-like, h3, .h3-like{
	font-weight:600;
	// text-align: left;
	margin-top:0;
	line-height:1.3;
}


h1, .h1-like{
	letter-spacing: -2px;
	font-size: 42px;
	line-height: 1.1;
	color:#fff;
}

h2, .h2-like{
	font-size: 30px;
	margin-bottom: 40px;
}

h3, .h3-like{
	font-size: 22px;
	letter-spacing:.5px;
	margin-bottom: 15px;
	// text-align: left;
}

.icon:before{
	padding-right:8px;
	vertical-align: bottom; //- когда иконки - строчные элементы, то требуется опустить их вниз, чтобы не висели
}

.nowrap{
	white-space: nowrap;
}

.no-padding{
	padding:0;
}

.hidden{
	display: none;
}

.light-text{
	font-weight:400;
}

.btn {
    display: inline-block;
    -webkit-border-radius: 0;
    border-radius: 0;
    letter-spacing: .2em;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    padding: 14px 18px;
}

.btn--transparent{
	border:1px solid;
	background-color:transparent;
	transition: all 0.5s ease-out;
	&:hover{
		background: $yellow;
		color: $darkbg;
		border-color: $yellow;
		transition: all 0.5s ease-out;
	}
}

.btn--transparent--colored{
	background: $yellow;
	border-color: $yellow;
	color: $darkbg;
	transition: all 0.5s ease-out;
	&:hover{
		border-color: $yellow;
		background: transparent;
		color: $yellow;
		transition: all 0.5s ease-out;
	}
}

.btn--promo{
	color:$darkbg;
	margin-top:30px;
	margin-bottom:50px;
	&:hover{
		color:$darkbg;
		opacity:1;
	}
}

.btn.focus, .btn:focus {
    box-shadow: none;
}

.btn--cta{
	background-color:#ffb623;
	border:1px solid transparent;
	color:#011a2e;
	transition: all 0.5s ease-out;
	&:hover{
		background-color:transparent;
		border:1px solid #ffb623;
		color:#ffb623;
		transition: all 0.5s ease-out;
	}
}

.small-title{
	position: relative;
	text-transform:uppercase;
	font-size: 12px;
	letter-spacing: 0.27em;
	color: $darkbg;
	padding-top:14px;
	margin-bottom: 30px;
}

.small-title:before{
	content:'';
	display:block;
	height:1px;
	width:45px;
	background-color: $darkbg;
	position:absolute;
	left:0;
	top:0;

}

.line-before{
	position:relative;
	padding-left:20px;
}

.line-before:before{
	content:'';
	display: block;
	height:1px;
	width:14px;
	position:absolute;
	top:50%;
	left:0;
	background-color:#fff;
	opacity:0.6;
}

.section-padding{
	padding:60px 0;
}

.header{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	// height:100px;
	z-index:1001; //- не должно быть меньше 1000
	font-weight:500;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing:.2em;
}

// .navbar{
// 	display:flex;
// 	width:100%;
// 	justify-content: space-between;
// 	align-items:center;
// 	padding:30px 20px;
// }

.navbar{
	display:flex;
	width:100%;
	// justify-content: space-between;
	align-items:center;
	padding:30px 20px;
}

.navbar--small{
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #333;
}

.header__logo{
  display: flex;
  height: 100%;
  align-items: center;
  z-index:2; //- на маленьких экранах при открытии меню остается вверху
  // width: 80%;
  margin-right:30px;
}

.nav-link{
  color: #fff;
}

.menu{
	visibility: hidden;
	opacity: 0;
	display:flex;
	flex-direction:column;
	position:fixed;
	z-index:1;
	top:0;
	// left:0;
	right:0;
	width:0;
	height:100%;
	background-color: $darkbg;
	justify-content: flex-end;
	align-items: center;
	// transition: all .4s ease-in-out;
}

.menu-list{
	display:flex;
	flex-direction:column;
	justify-content: space-around;
	// width:600px;
	align-items: center;
}

.menu-list li{
	margin-right:40px;
}

.menu__phone{
  color: #fff;
  color: $yellow;
  font-size:15px;
}

.menu__contacts{
	display:flex;
	justify-content: space-between;
	align-items: center;
	margin-left:auto;
	margin-right:20px;
	letter-spacing:0.5px;
}

.menu__cta{
	color:#fff;
	padding: 8px 16px !important;
	border:1px solid #fff;
	font-size:11px !important;
	margin-left:20px;
	transition: all 0.3s ease-out;
	&:hover{
		background:$yellow;
		border-color: $yellow;
		transition: all 0.3s ease-out;
		color: $darkbg;
	}
}

.menu__cta{
	display: none;
}


//- Menu stiles for small screens
.menu--visible{
	font-size: 18px;
	display:flex;
	justify-content: flex-start;
	padding-top:200px;
	visibility: visible;
	opacity:1;
	transition: all .4s ease-in-out;
	width: 100%;
	min-width: 320px;

	& .menu-list{
		margin-bottom:20px;
	}
	//& .menu-list li:not(:last-child){
	& .menu-list li{
		margin: 0 0 20px 0;
	}
	& .menu__contacts{
		flex-direction: column;
		// padding:0;
	}
	& .menu__phone{
		font-size:18px;
	}
	& .menu__cta{
		margin-left:0;
		margin-top:10px;
	}
}

.menu-button{
	min-width: 30px;
	width: 30px;
	// height:30px;
	position:relative;
	background:transparent;
	border:none;
	padding:0;
	z-index:2;
	order:3;
}

.menu-button span{
	display: block;
	position: absolute;
	height: 2px;
	background: #fff;
	opacity: 1;
}

.menu-button span{
	height: .125rem;
	position: absolute;
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
	width: 1.875rem;
	z-index: 2;
}

.menu-button--to-open{
	height:18px;
}

.menu-button--to-open span{
  &:nth-child(1){
    top: 0px;
    width: 100%;
  }
  &:nth-child(2){
     top: 7px;
     width: 70%;
  }
  &:nth-child(3){
    top: 14px;
    width: 40%;
  }
}


.menu-button--to-close span{
  &:nth-child(1){
	left: 7%;
	top: 2%;
	transform: rotate(45deg);
	width: 50%;
	z-index: 2
  }
  &:nth-child(2){
	top: 7px;
	transform: rotate(-45deg);
  }
  &:nth-child(3){
	right: 5%;
	top: 41%;
	transform: rotate(45deg);
	width: 50%;
  }
}

.menu-button--to-close{
	height:30px;
}

@media screen and (min-width: 576px){
.line-before{
	padding-left:90px;
}
.line-before:before{
	width:84px;
}
.menu__contacts{
	margin-right:30px;
}
}

@media screen and (min-width: 768px){
	.navbar{
		padding:50px;
	}
	.navbar--small{
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.menu--visible{
		width:50%;
	}
	.menu__phone{
	  font-size:20px;
	}
}

@media screen and (min-width: 1200px){
	.navbar{
		justify-content: space-between;
	}
	.menu-button{
		display:none;
	}
	.menu{
		position:static;
		flex-direction: row;
		// justify-content: space-between;
		visibility: visible;
		background-color:transparent;
		opacity: 1;
		max-width:1150px;
		left:0;
		width:100%;
	}
	.menu-list{
		flex-direction: row;
	}
	.menu__contacts{
		margin-right:auto;
	}
	.menu__cta{
		display: block;
	}

}




/*--------------MENU END------------------*/


/*--------------REPAIR PAGE -----------------*/

/*----COMMON THEMES------*/
.theme-grey{
	// background-color: #e1e2e2;
	// color:#4d5961;
	background: $lightbg;
}

.theme-dark{
	background: $darkbg;
	// background-color: #4d5961;
	color: #fff;
}

.theme-dark p{
	opacity: 0.8;
}

/*------------------------*/



// .intop{
// 	background: url('../img/bg-image.jpg');
// 	background-size:cover;
// 	height: 100%;
// 	max-height: 800px;
// 	width: 100%;
// 	color:#fff;
// 	display: flex;
// 	align-items: center;
// 	min-height: 600px;
// }

// .intop__h1{
// 	margin-bottom:0;
// 	padding:0;
// }

// .intop__btn_cta{
// 	display: block;
// 	width: 400px;
// 	margin:auto;
// 	padding:12px 25px;
// 	font-size: 20px;
// }

// header{
// 	position:absolute;
// 	left:0;
// 	top:0;
// 	width:100%;
// 	height: auto;
// 	padding-top:20px;
// }

.intop__offer{
	width:100%;
	padding:40px;
}

.h1-repair{
	max-width:530px;
	// margin-bottom: 40px;
	margin-bottom: 30px;
	font-size: 34px;
	padding-right: 40px;
}

.main-repair__img{
	right:0;
}

@media screen and (min-width: 992px){
	.main-repair__img{
		padding-bottom:86px;
	}
}

/*---------PROMO--------- */

.promo{
	position: relative;
	z-index:0;
	padding-bottom:0;
}

.promo__img{
	z-index: 100;
}

.promo__special{
	color:#111;
	font-weight: 400;
}

.promo__list{
	margin-bottom:25px;
}

.promo__list li:before{
	content:'-';
	padding-right:5px;
}

.promo__btn{
	margin-bottom: 60px;
}

.promo__text{
	max-width: 500px;
}

/*------SERVIZI--------*/

.services{
	position:relative;
	overflow:hidden;
	padding-top:60px;
}

.services:before {
	content: "Ristrutturazioni";
	font-weight: 700;
	color: $lightbg;
	opacity:.8;
	line-height:1;
	display: block;
	font-size:200px;
	position: absolute;
	// width: 100%;
	// height: 100%;
	top: 0;
	left:95%;
	letter-spacing: -4px;
	z-index: -1;
	transform: rotate(90deg);
	transform-origin: center left;
}

.services__inner {
    margin-top: 20px;
    margin-bottom: 20px;
}

.services h3{
	text-align: left;
}

.services p{
	margin-bottom: 0;
}

.services__text{
	display: flex;
	align-items: center;
	height: 100%;
}

.services__frame_right, .services__frame_left{
	position: relative;
	margin-bottom: 40px;
}



/*-------MEETING--------*/

.meeting{
	margin-top: 20px;
	// height:281px;
	padding:60px 0;
	background:url(../img/techdesign.jpg) no-repeat;
	color: #fff;
	position: relative;
	display: flex;
	align-items: center;
}

.meeting:after{
	content:'';
	display: block;
	position: absolute;
	top:0;
	right:0;
	width:15%;
	bottom:0;
	// background-color: #4d5961;
}

.meeting__btn{
	margin-top: 40px;
}


/*---------PLUSES--------- */

.pluses__inner{
	// padding:50px;
	// border:1px solid rgba(77, 89, 97, 0.2);
	display: flex;
	justify-content: center;
	flex-direction:column;
	// text-align:center;
	max-width: 300px;
	margin:auto;
	margin-bottom: 30px;
}

.pluses__icon{
	margin-bottom: 10px;
}
.pluses__icon img{
	max-width:60px;
	max-height:60px;
}

.pluses__text{
	// padding-left:30px;
	// max-width: 300px;
}

.pluses p{
	opacity:0.8;
}

/*-------ABOUT--------*/

// .about{
// 	background: url(../img/about-img.jpg) no-repeat $lightbg;
// 	-webkit-background-size: 
// 	background-size: auto 100%;
// 	padding: 0;
// }

.about{
	padding: 0;
}

.about__img{
	height:400px;
}

.about__img img{
	height:100%;
	width:100%;
	object-fit: cover;	
}

.about__text{
	padding:50px 0 0 0;
}




/*-------SELECT US--------*/
.select-us{
	background-color: #555;
	background-image: url(../img/bg-edilmaster360.svg) no-repeat;
	padding:80px 0;
	color:#fff;
	text-align: center;
}

/*--------WORKS REPAIR-------*/
.works-repair{
	display: flex;
	justify-content: center;
}

.works-repair__text{
	padding:110px 60px;
	color:#fff;
}

/* Work Hover*/

.works-repair__img {
	color: #fff;
	position: relative;
	overflow: hidden;
	background: #000000;
	margin-bottom: 30px;
	max-width:480px;	
}

.works-repair__img * {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.works-repair__img img {
	display:block;
	opacity: 1;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}

.works-repair__caption {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 3em 3em;
	width: 100%;
	height: 100%;
	text-align:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.works-repair__caption::before {
	position: absolute;
	top: 50%;
	right: 30px;
	bottom: 50%;
	left: 30px;
	border-top: 1px solid rgba(255, 255, 255, 0.8);
	border-bottom: 1px solid rgba(255, 255, 255, 0.8);
	content: '';
	opacity: 0;
	background-color: #ffffff;
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transition-delay: 0.6s;
	transition-delay: 0.6s;
	max-width: 400px;
	display: block;
	margin: auto;
}

.works-repair__location,
.works-repair__img p {
	margin: 0 0 5px;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s,-webkit-transform 0.35s,-moz-transform 0.35s,-o-transform 0.35s,transform 0.35s;
}

.works-repair__location {
	font-size:14px;
	word-spacing: -0.15em;
	font-weight: 300;
	-webkit-transform: translate3d(0%, 50%, 0);
	transform: translate3d(0%, 50%, 0);
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

.works-repair__img p {
	font-weight: 400;
	line-height: 1.1;
	font-size: 20px;
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.works-repair__img:hover img {
	opacity: 0.35;
}

.works-repair__img:hover .works-repair__location {
	opacity: 1;
	-webkit-transform: translate3d(0%, 0%, 0);
	transform: translate3d(0%, 0%, 0);
	-webkit-transition-delay: 0.6s;
	transition-delay: 0.6s;
}

.works-repair__img:hover .works-repair__caption p {
	opacity: 0.9;
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.works-repair__img:hover .works-repair__caption::before {
	background: rgba(255, 255, 255, 0);
	top: 30px;
	bottom: 30px;
	opacity: 1;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

.works-repair__form{
	margin-top: 40px;
	text-align:center;
}

/*--------CLIENTS-------*/
.clients{
	background: $lightbg;
}

.clients img{
	opacity:0.4;
	transition: all 0.5s ease-out;
	-webkit-transition: all 0.5s ease-out;
}

.clients img:hover{
	opacity: 1;
	transition: all 0.5s ease-out;
	-webkit-transition: all 0.5s ease-out;
}

/*--------------END INDEX PAGE -----------------*/

/*-----FLOOR MAIN-----*/

.main-floor{
	height:100%;
	min-height:630px;
	max-height:1080px;
	display:flex;
	justify-content:space-between; 
	position: relative;
	background-color: $darkbg;
	color:#fff;
	overflow:hidden;
}

.sidebar-social{
	position: absolute;
	// width:150px;
	right:0;
	padding-right:15px;
	padding-top:120px;
	color:#fff;
	z-index:1000;
	// padding-top:200px;
	// padding-left:40px;
	&__facebook{
		// margin-bottom: 40px;
		margin-bottom: 20px;
	}
	& a{
		color:#fff;
		// font-size:24px;
		font-size:18px
	}
}

.sidebar-social__light a{
		color:$darkbg;
}

.main-floor__text{
	position: relative;
	padding:0 20px;
	z-index:1000;
	display: flex;
	flex-direction: column;
	justify-content:center;
	margin:auto;
	width:100%;
}

.h1-floor{
	max-width:410px;
	margin-bottom: 40px;
}

.main-floor__description{
	font-size: 20px;
	max-width: 443px;
	margin-bottom: 40px;
	padding-right:40px;
	font-weight:400;
}

.main-floor__note{
	margin-bottom: 5px;
	letter-spacing: 1px;
	font-size:16px;
	font-weight: 500;
}

.btn__main{
	font-size:12px;
	padding:15px 25px;
}

@keyframes black_bgmove{
    0% {width: 100%;}
    50% {width: 0%;}
}


.main__img{
	width:75%;
	height:90%;
	left:25%;
	position:absolute;
}

.slick-list{
	height: 100%;
}

.slick-track{
	height: 100%;
}

.slider-item{
	height: 100%;
}

.slider-item__img{
	height: 100%;
}

.black-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: $darkbg;
    z-index: 109;
}

.main__img .slick-active .black-bg {
    animation-name: black_bgmove;
    animation-duration: 3s;
}

.main__img img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}


@media screen and (min-width: 576px){
	.h1-floor{
		font-size: 72px;
	}
	.h1-repair{
		font-size:60px;
	}
	.main-floor__text{
		position: relative;
		left:10%;
		// padding:0;
	}
	.main-floor__note{
		margin-bottom: 20px;
	}
	.main-floor__description{
		font-size: 22px;
	}
	.btn__main{
		font-size:14px !important;
		padding:20px 35px !important;
	}
}

@media screen and (min-width: 768px){
	.main-floor{
		min-height:800px;
	}
	.sidebar-social{
		right:auto;
		padding-top:200px;
		padding-left:40px;
		&__facebook{
			margin-bottom: 40px;
		}
		& a{
			font-size:24px;
		}
	}
	.main-floor__text{
		position: relative;
		left:150px;
	}

}

@media screen and (min-width: 992px){
	.h1-repair{
		font-size:72px;
	}
	.main-floor__text{
		left:265px;
	}
	.main-floor__description{
		font-weight:300;
	}
	.main-floor__img{
		position:relative;
		padding-bottom:86px;
		height:auto;
		left:auto;
	}
	.main-repair__img{
		height:auto;
	}
}


/*-----PROPERTIES-------*/

.properties{
	padding-top:60px;
}

.properties__h2{
	max-width: 380px;
}

// .properties__img{
// 	margin-bottom:10px;
// }

.properties__item{
	// width:33.3%;
	// flex-basis:340px;
	margin-bottom: 40px;
}

@media screen and (min-width: 576px){
	.properties{
		padding-top: 120px;
	}
	.properties__text-wrap{
		max-width:450px;
	}
}

@media screen and (min-width: 768px){
	.properties{
		background:url('../img/floor/bg_cemento-stampato.png') no-repeat;
		background-position: 400% 5%;
		background-size:90% auto;
		overflow:hidden;
	}
	.properties__item{
		display:flex;
	}
}

@media screen and (min-width: 992px){
	.properties{
		padding-bottom: 120px;
	}
	.properties__item{
		display:block;
		margin-bottom: 0;
	}
}

@media screen and (min-width: 1200px){
	.properties{
		background-position: 120% 0;
		background-size:auto auto;
		overflow:hidden;
	}
}


/*--------------USES-----------------*/

// .uses__images{
// 	display: flex;
// }

// .uses__images__left{
// 	width:33%;
// }
.uses{
	position: relative;
}

// .uses:before{
// 	content:'';
// 	display:block;
// 	position: absolute;
// 	height:100%;
// 	top:0;
// 	left:0;
// 	width:500px;
// 	background-color: $lightbg;
// 	z-index:-1;
// }

.uses__images{
	margin-bottom: 60px;	
}

.uses__images__right{
	margin-left:-20px;
	// padding-right:60px;
}

.icon-check:before{
	color: $yellow;
}

.uses__examples{
	padding: 40px;
	background: #545353;
	color: #fff;
	display: flex;
	justify-content: flex-start;
	flex-wrap:wrap;
}

.uses__example{
	margin-bottom: 20px;
	flex-basis:160px;
}

/*--------------COSTS-----------------*/

.costs{
	position: relative;
	overflow:hidden;
}

.costs__img-left{
	// position:relative;
	padding-right:100px;
}

.costs__img-right{
	// position:relative;
	position: absolute;
	// top:100px
	bottom:-100px;
	right:0;
	padding-left:100px;
	padding-right:15px;
}

.costs__text {
    margin-top: 140px;
}
// .costs__img-right{
// 	position: absolute;
// 	top:40%;
// 	right:0;
// 	max-width: 35%;
// 	&:before{
// 		content:'';
// 		position:absolute;
// 		display:block;
// 		width:300px;
// 		height:100px;
// 		top:-90px;
// 		left:30%;
// 		background: $lightbg;
// 		z-index:-1;
// 	}
// }

.costs__item{
	max-width:400px;
}

.costs__item:before{
	display:block;
}

.costs__item1 {
    margin-bottom: 40px;
}

.costs__item1:before{
	content:url('../img/floor/costs-icon-1.png');
}
.costs__item2:before{
	content:url('../img/floor/costs-icon-2.png');
}

.costs__img-title{
	display:none;
}

.costs__img-title:after{
	content:'';
	display: inline-block;
	height:1px;
	width:84px;
	position:absolute;
	margin-left:10px;
	top:50%;
	background-color: $darkbg;
	opacity:0.6;
}


/*--------------Form renew (advert) -----------------*/

.renew{
	background: $lightbg;
}

@media screen and (min-width: 768px) and (max-width: 991px){
.renew{
	background: $lightbg url('../img/promo-bg.png') no-repeat left top;
}
}

@media screen and (min-width: 1200px) and (max-width: 1500px){
.adv__box{
	padding-left:100px;
}
}

@media screen and (min-width: 1200px) {
.renew{
	background: $lightbg url('../img/promo-bg.png') no-repeat left top;
}
}

.red{
		color: #b12b15;
}

.adv__title{
	font-weight: 700 !important;
}

.btn--adv{
	margin-bottom:60px;
	padding:15px 30px !important;
}

@media screen and (min-width: 768px) {
.btn--adv{
	margin-top:30px;
}
}

@media screen and (min-width: 992px) {
.btn--adv{
	margin-bottom:0;
}
}
// Общие свойства для подобных форм
.form__title{
	font-size:26px;
	font-weight:600;
	margin-bottom:25px;
	line-height:1.4;
}

.form__title__strong{
	font-weight: 600;
}

.form__description{
	margin-bottom: 30px;
}

.form-group{
	margin-bottom: 1rem;
}

.form-control{
	display: block;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: $darkbg;
	background-color: transparent;
	background-clip: padding-box;
	border:none;
	border-bottom: 1px solid #ced4da;
}

.form-control::placeholder {
	font-size: 14px;
	letter-spacing: 0.18em;
}

.form-control:focus::placeholder{
	opacity: 1;
}

.form-control:focus{
	border-bottom: 1px solid $darkbg;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #e0162a;
    font-weight:400;
    font-size:12px;
}

.privacy__wrap{
	margin-top:40px;
}

.privacy__text{
	font-size:14px;
	font-weight:400;
}

.link-privacy {
    display: inline-block;
    color: $darkbg;
    border-bottom: 1px dotted $darkbg;
    opacity:.8;
}

.default-checkbox_hidden {
    opacity: 0 !important;
}

.rules {
    top: 0;
    left: 0;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
	height: calc(2.25rem + 2px);
	font-size: 1rem;
	line-height: 1.5;
}

.custom-control-indicator{
	color: #26a69a;	
}

.custom-control.custom-checkbox{
  .custom-control-input:checked ~ .custom-control-indicator{
    border-color: var(--color);
    transform: rotateZ(45deg) translate(1px, -5px);
    width: 10px;
    border-top: 0 solid #fff;
    border-left: 0 solid #fff;
  }
  .custom-control-indicator{
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #aaa;
    transition: .3s;
  }
}

.form-callback__btn{
	float:right;
	margin-top:10px;
}

.ajax-loader {
    display: none;
}

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
    display: block;
}

.form-result{
  margin-top: 20px;
  font-size: 18px;
  position: relative;
  display: none;
}

.result_error {
    color: red;
    background-color: #fbdddd;
    padding: 10px 30px 10px 20px;
    border: 1px solid red;
}
.result_success {
    color: green;
    background-color: #e7f0e7;
    padding: 10px 30px 10px 20px;
    border: 1px solid green;
  }

.submit__wrap{
	display: flex;
	justify-content: space-between;
	align-items:center;
}

/*--------------WORKS-----------------*/

.works{
	position: relative;
	overflow:hidden;
}

.works:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
	background:url('../img/floor/bg_cemento-stampato.png') no-repeat 20% 3%;
	transform: rotate(90deg);
}

.works__column{
	margin-top:30px;
}
.works__column img{
	margin-bottom: 30px;
	display: block;
}

.works__item{
	position: relative;
	&:hover{
		opacity:1;
	}
}

.works__item::after {
    content: '+';
    color: #fff;
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 30px;
    font-weight: 00;
    line-height: 1;
    border-radius: 50px;
    border: 1px solid #fff;
    width: 30px;
    height: 30px;
    text-align: center;
}


.works__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3em 3em;
    width: 100%;
    height: 100%;
    opacity:0;
    background:rgba(0, 0, 0, 0.8);
    color:#fff;
    transition: all ease-out 0.5s;
}

.works__caption::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 30px;
    bottom: 50%;
    left: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    opacity: 0;
    background-color: #ffffff;
    transition: all 0.4s;
    transition-delay: 0.6s;
    // max-width: 400px;
    // margin: auto;
}

.works__caption::before{
	position:absolute;
	bottom:40px;
	right:30px;
	font-size:24px;
}

.fancybox-button{
	background:transparent;
}

.fancybox-button:before{
	line-height: 1;
	font-size:38px;
	padding:0;
}

.tofacebook{
	color:#313131;
	&:hover{
		color: #313131;
	}
}

.tofacebook:after{
	content: "→";
	// font-size: 20px;
	padding-left: 8px;
	transition: all .3s ease-out;
}

.tofacebook:hover::after {
    padding-right: 4px;
    padding-left: 4px;
    transition: all .3s ease-out;
}

.btn__works{
	display:block;
	margin:auto;
	margin-top:30px;
}

/*--------------TERMS -----------------*/
.terms{
	position: relative;
	overflow:hidden;
	padding-bottom:60px;
	// background:url('../img/floor/how_we_work.jpg') 100% 50% no-repeat;
}
.terms__item{
	margin-bottom: 40px;
	padding-left:40px;
	max-width: 400px;
	position: relative;
	&:before{
		transform:rotate(-90deg);
		position: absolute;
		top:65px;
		left:0;
	}
	&:after{
		content:'';
		display: block;
		height:60px;
		width:1px;
		position:absolute;
		top:0;
		left:10px;
		background-color: $darkbg;
		opacity:0.6;
	}
}

.terms__img{
	position: relative;
	margin-bottom:120px;
	// display:flex;
	// justify-content: flex-end;
	// align-items:center;
	&__inner{
		position:relative;
	}
	&__inner:after{
		content:'';
		display:block;
		position:absolute;
		width:100%;
		height:100%;
		// top:-60px;
		top:60px;
		right:-60px;
		background: $yellowbg;
		z-index:-1;
	}
}

// .terms__img{
// 	position: absolute;
// 	top:0;
// 	right:0;
// 	width:35%;
// 	max-height:100%;
// 	display:flex;
// 	justify-content: flex-end;
// 	align-items:center;
// 	&__inner{
// 		position:relative;
// 	}
// 	&__inner:after{
// 		content:'';
// 		display:block;
// 		position:absolute;
// 		width:100%;
// 		height:100%;
// 		// top:-60px;
// 		top:60px;
// 		right:-60px;
// 		background: $yellowbg;
// 		z-index:-1;
// 	}
// }

@for $i from 1 to 6{
	.terms__item__#{$i}:before{
		content:'0#{$i}.';
	}
}

/*--------------CONTACTS-----------------*/
.contacts{
	background: $lightbg;
}

.row__contacts{
	align-items:center;
}


/*--------------CALLBACK POPUP -----------------*/


.callback-popup__overlay{
  z-index: 1040;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: .8;
  filter: alpha(opacity = 80);
  cursor: pointer;
  display: none;
}

.callback-popup__content{
  width: 100%;
  max-width: 400px;
  // color: #fff;
  background: $lightbg;
  position: fixed;
  display: none;
  opacity: 0;
  padding: 30px 40px 40px 40px;
  z-index: 1041;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.callback-popup__btn-close{
  width: 21px;
  height: 21px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: block;
  line-height: 21px;
  font-size: 50px;
  color: #222;
  z-index: 10;
}

.callback .form-control{
  border: 1px solid #ccc;
  box-shadow: none;
  &:focus{
    box-shadow: none;
  }
}

.popup__message {
    height: 100px;
    width: 100%;
}

/*--------------CONTATTI -----------------*/

.main-contacts{
	min-height:100%;
	width:100%;
	background: $darkbg;
	padding-top:160px;
	color:#fff;
	overflow: hidden;
}

.contacts__h1{
	font-size:42px;
	margin-bottom: 40px;
}

.contacts__text {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 600px;
    margin: auto;
    margin-bottom:80px;
}

.contacts__location{
	margin-top:20px;
	margin-bottom:60px;
}
// .contacts__text__line1{
// 	display: flex;
// 	align-items:center;
// 	justify-content:space-between;
// 	margin-bottom:40px;
// }

.contacts__message{
	height:100px;
	width:100%;
}

.contacts__phone{
	color:#fff;
}
.contacts__social{
	display:flex;
	width:100px;
	font-size:24px;
	justify-content: space-between;
	// margin-top:30px;
	// margin-bottom:30px;
}
.contacts__social a{
	color:#fff;
}
.form-contacts .form-control{
	color: #fff;
	border-bottom:1px solid rgba(255,255,255, .4);
}

.form-contacts .form-control:focus {
    border-bottom: 1px solid #fff;
}

.contacts__link-privacy{
	color:rgba(255, 255, 255, .6);
	display: inline-block;
}

.contacts__map{
	position: relative;
	&::after{
		content:'';
		position:absolute;
		top:0;
		left:0;
		height:100%;
		width:100%;
		background: $darkbg;
		opacity:.7;
		transition: all 0.5s ease-out;
	}
	&:hover:after{
		opacity:0;
		transition: all 0.5s ease-out;
	}
}

.contacts__map iframe{
	display: block;
	width:100%;
}
/*--------------FOOTER-----------------*/

.footer{
	background: $darkbg;
	padding:60px 0 0 0;
	font-size:14px;
	color:rgba(255,255,255,.8);
	letter-spacing: 0.5;

	&__wrap{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-basis: 365px;
		padding-bottom: 60px;
	}
	
	&__item:not(:last-child){
		margin-bottom: 40px;
	}

	&__item1{
		max-width:300px;
	}

	&__title{
		font-weight: 600;
		font-size: 18px;
		margin-bottom:25px;
	}

	& a{
		color:rgba(255,255,255,.8);
		&:hover{
			opacity:.6;
		}
	}

	&__social{
		font-size:22px;
		display:flex;
		justify-content: space-between;
		width:60px;
		margin-bottom: 10px;	
	}

	&__phone{
		margin-bottom: 10px;	
	}

	&__bottom{
		background: #383532;
		text-align:center;
		padding:10px 20px;
		opacity: .4;
	}

	&__privacy{
		display: inline-block;
	}
}


/*--------------MEDIA-----------------*/

@media screen and (min-width: 576px){
	.contacts__h1{
		font-size:72px;
		margin-bottom: 60px;
	}
	h2, .h2-like{
		font-size: 36px;
		margin-bottom: 60px;
	}
	.section-padding{
		padding:120px 0;
	}
	.services{
		padding-top:120px;
	}
	.meeting{
		margin-top: 70px;
	}
	.promo{
		padding-bottom: 0;
	}
	.form__title{
		font-size:32px;
	}
	// .form__description{
	// 	margin-bottom: 40px;
	// }
	.btn{
		font-size: 12px;
		padding:15px 25px;
	}
	.btn__works{
		margin-top:40px;
	}
	.terms{
		padding-bottom:120px;
	}
}

@media screen and (min-width: 768px){
	.services__inner {
	    margin-top: 0;
	    margin-bottom: 0;
	}
	.pluses__inner{
		border:1px solid rgba(77, 89, 97, 0.2);
		padding:50px;
		max-width:100%;
	}
	.about__img{
		height:100%;
		background: $yellowbg;
		position: relative;
		padding-bottom:60px;
		z-index: 0;
		&:after{
			content:"";
			position:absolute;
			bottom:0;
			left:0;
			width:100%;
			height:60px;
			background:#fff;
			z-index: -1;
		}
	}
	.about__img img{
		// padding-bottom:60px;
		height:100%;
		width:100%;
		object-fit: cover;
		display: block;
		position: absolute;
		// top:60px;
		// left:60px;
	}
	.about:before{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 40%;
	}
	.about__text{
		padding-top:0;
		padding-left:40px;
	}
	.works-repair__col1{
		margin-top: 60px;	
	}

	.works-repair__col2{
		margin-top: 30px;	
	}
	.works-repair__img p{
		font-size:14px;
	}
	.works-repair__location{
		display:none;
	}
	.form__description{
		margin-bottom: 0;
	}
	.costs__img-title{
		display:block;
		position: relative;
		//position: absolute;
		transform: rotate(-90deg);
		// transform-origin: -3% 50%;
		transform-origin: top left;
		font-size: 11px;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-weight:400;
		bottom:10px;
		left:-25px;
	}
	.costs__img-right{
		bottom:-200px;
	}

	.costs__text{
		margin-top:240px;
	}
	.costs__item1 {
	    margin-bottom: 0;
	}
	.footer__wrap{
		flex-direction: row;
	}
	.footer__item:not(:last-child){
		margin-bottom: 0;
	}

}

@media screen and (min-width: 992px){
	.works__item::after {
		display: none;
	}
	.promo:after{
		content:'';
		display: block;
		position: absolute;
		right:0;
		bottom:0;
		width:45%;
		height:440px;
		// background-color:#b9bdbf;
		background: $lightbg;
		z-index:-1;
	}
	.promo{
		padding-bottom: 120px;
	}
	.promo__btn{
		margin-bottom: 0;
	}
	.pluses__inner{
		flex-direction: row;
	}
	.pluses__text{
		padding-left:30px;
	}
	.about__img img{
		padding-bottom:60px;
		top:60px;
		left:60px;
	}
	.about__text{
		padding-left:100px;
	}
	.works-repair__img p{
		font-size:20px;
	}
	.works-repair__location{
		display:block;
	}
	.costs__img-right{
		bottom:-100px;
	}

	.costs__text{
		margin-top:0px;
	}
	.costs__item2{
		margin-top:80px;
	}
	.costs__img-title:after{
		display:none;
	}
	.works__column1{
		margin-top: 80px;
	}

	.works__column2{
		margin-top: 40px;
	}

	.works__column3{
		margin-top: 0;
	}

	.works__column4{
		margin-top: 80px;
	}
}

@media screen and (min-width: 1200px){
	.sidebar-social {
	    position: fixed;
	}
	.services__frame_right:after{
		content:'';
		border:1px solid $darkbg;
		opacity: 0.2;
		position: absolute;
		display: block;
		width:870px;
		height: 215px;
		top:19px;
		right:100px;
	}

	.services__frame_left:after{
		content:'';
		border:1px solid $darkbg;
		opacity: 0.2;
		position: absolute;
		z-index:1;
		display: block;
		width:870px;
		height: 215px;
		top:19px;
		left:100px;
	}
	.about__text{
		padding-left:170px;
	}
	.uses__images{
		margin-bottom: 0;	
	}
	.uses:after{
		content:'';
		display:block;
		position: absolute;
		height:100%;
		top:-50px;
		right:0;
		width:150px;
		height:300px;
		background-color: $yellowbg;
		z-index:-1;	
	}
	.costs__img-title:after{
		display:inline-block;
	}
	.works__item:hover{
		opacity:1;
	}

	.works__item:hover .works__caption::after {
	    background: rgba(255, 255, 255, 0);
	    top: 30px;
	    bottom: 30px;
	    opacity: 1;
	    transition: all ease-out 0.5s;
	}

	.works__item:hover .works__caption {
	    opacity: 1;
	    transition: all ease-out 0.5s;
	}
	.terms{
		padding:120px 0;
	}
	.terms__img{
		position: absolute;
		top:0;
		right:0;
		width:35%;
		height:100%;
		// margin-bottom:120px;
		display:flex;
		justify-content: flex-end;
		align-items:center;
	}	
	.terms__item{
		max-width: 300px;
	}
	.terms__item__3{
		margin-top: 40px;
	}
	.terms__img{
		// top:auto;
		// bottom:0;
		// padding-bottom: 120px;
		&__inner:after{
			top:-60px;
		}
	}
	.contacts__text {
	    padding-left: 200px;
	    padding-right: 100px;
	    max-width:100%;
	}
}